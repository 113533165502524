import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { Button } from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';

import StyledFormWrapper from "../StyledComponents/Form/FormWrapper";

const init = (name = "", dob = null) => {
    return {
        name: name,
        dob: dob,
    }
};

const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

const EditPlayerForm = (props) => {
    const { player, handleRefresh } = props;
    console.log("player", player);
    const [formData, setFormData] = useReducer(formReducer, init(player.name, player.dob));
    const navigate = useNavigate();

    const handleEditPlayer = (event) => {
        event.preventDefault();
        console.log("handleEditPlayer");
        console.log("formData", formData);

        const editPlayer = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/players/${player.id}`;
            const response = await axios.put(url, formData);
            console.log("response", response);

            handleRefresh();
        };
        editPlayer();
    }

    const handleChange = event => {
        console.log("event in handleChange", event);
        let name = null;
        let value = null;
        if (event) {
            if (event.target) {
                name = event.target.name;
                value = event.target.value;
            } else {
                name = 'dob';
                value = event;
            }
            setFormData({
                name: name,
                value: value,
            });
        }
      }

    return (
        <>
            <h2>New Player</h2>
            <StyledFormWrapper onSubmit={handleEditPlayer}>
                <FormControl>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label="Birthday"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel htmlFor="player_value">Value</InputLabel>
                    <Input
                        id="player_value"
                        name="player_value"
                        type="number"
                        onChange={handleChange}
                    />
                </FormControl>
                <Button type="submit">Update Player</Button>
            </StyledFormWrapper>
        </>
    )
};

export default EditPlayerForm