import React, { useEffect, useState } from "react";
import PageTitle from "../Components/PageTitle";
import PlayerTable from "../Components/PlayerTable";
import StyledMainArticle from "../StyledComponents/MainArticle";
import axios from "axios";
import LineChart from "../Components/LineChart";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import SeasonsDropdown from "../Components/SeasonsDropdown";

const Balance = (props) => {
    const [seasons, setSeasons] = useState([]);
    const [seasonId, setSeasonId] = useState(null);
    const [seasonTransfers, setSeasonTransfers] = useState({});
    const [transferInSum, setTransferInSum] = useState(0);
    const [transferIns, setTransferIns] = useState([]);
    const [transferOutSum, setTransferOutSum] = useState(0);
    const [transferOuts, setTransferOuts] = useState([]);
    const [transferData, setTransferData] = useState([]);
    const [upcomingTransfers, setUpcomingTransfers] = useState([]);

    const headers = [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "age_at_transfer",
            label: "Age at Transfer",
        },
        {
            key: "transfer_amount",
            label: "Transfer Amount",
        },
        {
            key: "transfer_date",
            label: "Transfer Date",
        },
    ];

    const upcomingTransfersHeader = [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "value",
            label: "Player Value",
        }
    ]

    useEffect(() => {
        const getSeasons = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/seasons`;
            const response = await axios.get(url);
            console.log("response", response);
            setSeasons(response.data);

            if (response.data?.length > 0) {
                const today = new Date();
                const currentSeason = response.data.find(season => {
                    const startDate = new Date(season.start_date);
                    const endDate = new Date(season.end_date);
                    return startDate <= today && today <= endDate;
                });
                console.log("currentSeason:", currentSeason)
                setSeasonId(currentSeason.id);
            }
        };
        getSeasons();
    }, []);

    useEffect(() => {
        const getSeasonTransfers = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/transfers/season/${seasonId}`;
            const response = await axios.get(url);
            console.log("response", response);
            if (response) {
                setSeasonTransfers(response.data);
            }
        };

        const getUpcomingTransfers = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/players/transfer-within-season/${seasonId}`;
            const response = await axios.get(url);
            console.log("response", response);
            if (response) {
                setUpcomingTransfers(response.data);
            }
        };

        if (seasonId) {
            getSeasonTransfers();
            getUpcomingTransfers();
        }
    }, [seasonId]);

    const handleSeasonChange = (event) => {
        event.preventDefault();
        console.log("event", event.target.value);
        setSeasonId(event.target.value);
    };

    const createSeasonOptions = () => {
        if (seasons && seasons.length > 0) {
            return seasons.map((season) => {
                return (
                    <option key={season.id} value={season.id} defaultChecked>{season.season_num}</option>
                );
            });
        }
        return [];
    };

    const transformTransferData = (transfers) => {
        const currentSeason = seasons.find((season) => season.id === seasonId);

        if (currentSeason && currentSeason.start_date && currentSeason.end_date) {
            const today = new Date()
            let startDate = new Date(currentSeason.start_date);
            let endDate = new Date(currentSeason.end_date);
            if (isBefore(startDate, today) && isBefore(today, endDate)) {
                endDate = today;
            }
            const days = eachDayOfInterval({
                start: startDate,
                end: endDate,
            });
        
            let sum = 0;
            const transferData = days.map((day) => {
                const date = day.toISOString().split("T")[0];
                const transferIn = transfers.transfer_in.filter((transfer) => transfer.transfer_date === date);
                const transferOut = transfers.transfer_out.filter((transfer) => transfer.transfer_date === date);

                sum -= transferIn.reduce((acc, curr) => acc + curr.transfer_amount, 0);
                sum += transferOut.reduce((acc, curr) => acc + curr.transfer_amount, 0);

                return {
                    date,
                    balance: sum,
                };
            });

            setTransferData(transferData);
        } else {
            setTransferData([]);
        }
    };

    useEffect(() => {
        if (seasonTransfers){
            if (seasonTransfers.transfer_in) {
                setTransferInSum(seasonTransfers.transfer_in.reduce((sum, player) => sum + player.transfer_amount, 0));
                setTransferIns(seasonTransfers.transfer_in);
            }

            if (seasonTransfers.transfer_out) {
                setTransferOutSum(seasonTransfers.transfer_out.reduce((sum, player) => sum + player.transfer_amount, 0));
                setTransferOuts(seasonTransfers.transfer_out);
            }

            transformTransferData(seasonTransfers);
        } else {
            setTransferInSum(0);
            setTransferIns([]);
            setTransferOutSum(0);
            setTransferOuts([]);
            setTransferData([]);
        }
    }, [seasonTransfers]);

    const totalUpcomingTransfersValue = () => {
        if (upcomingTransfers && upcomingTransfers.length > 0) {
            return upcomingTransfers.reduce((acc, curr) => acc + curr.value, 0);
        }
        return 0;
    };

    return (
        <>
            <PageTitle title="Season Balance" />
            <StyledMainArticle>
                <section>
                    {/* <select name="seasonId" onChange={handleSeasonChange}>
                        {createSeasonOptions()}
                    </select> */}
                    {seasons && seasons.length > 0 && seasonId && (
                        <SeasonsDropdown
                            seasons={seasons}
                            handleChange={handleSeasonChange}
                            value={seasonId}
                        />
                    )}
                </section>
                <section>
                    <h2>Summary</h2>
                    <div>
                        <h3>Profit</h3>
                        <p>{(transferOutSum - transferInSum).toLocaleString()}</p>
                    </div>
                    <div>
                        <h3>Breakdown</h3>
                        <p>Transfer In: {transferInSum.toLocaleString()}</p>
                        <p>Transfer Out: {transferOutSum.toLocaleString()} (Estimate for season: {(transferOutSum + totalUpcomingTransfersValue()).toLocaleString()})</p>
                    </div>
                </section>
                <section>
                    <h2>Charts</h2>
                    <LineChart
                        data={transferData}
                    />
                </section>
                <section>
                    <h2>Upcoming Transfers ({upcomingTransfers.length})</h2>
                    <h3>Estimated Income: {totalUpcomingTransfersValue().toLocaleString()}</h3>
                    <PlayerTable
                        headers={upcomingTransfersHeader}
                        players={upcomingTransfers}
                    />
                </section>
                <section>
                    <h2>Transfers</h2>
                    <section>
                        <h3>Transfer Ins ({transferIns.length})</h3>
                        <PlayerTable
                            headers={headers}
                            players={transferIns}
                            playerIdKey="player_id"
                        />
                    </section>
                    <section>
                        <h3>Transfer Outs ({transferOuts.length})</h3>
                        <PlayerTable
                            headers={headers}
                            players={transferOuts}
                            playerIdKey="player_id"
                        />
                    </section>
                </section>
            </StyledMainArticle>
        </>
    )
};

export default Balance;