import React, { useReducer } from "react";
import axios from "axios";

import StyledFormWrapper from "../../StyledComponents/Form/FormWrapper";
import { changeLocalToUTCDate } from "../../Custom/Util";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

const init = (rating) => {
    return {
        rating: rating?.rating || "",
        update_date: rating?.update_date || null,
    }
};

const formReducer = (state, action) => {
    if (action.type === "reset") {
        return {};
    }
    return {
        ...state,
        [action.name]: action.value
    }
};

const RatingForm = (props) => {
    const { playerId, rating, handleRefresh } = props;
    const [formData, setFormData] = useReducer(formReducer, init(rating));

    const handleRatingSubmit = (ratingData) => {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/ratings`;

        const resetForm = () => {
            setFormData({ type: "reset" });
        }

        const postNewRating = async () => {
            const response = await axios.post(url, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        const putRating = async () => {
            const response = await axios.put(`${url}/${rating.id}`, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        if (rating && rating.id) {
            putRating();
        } else {
            postNewRating();
        }
    };

    const handleRatingForm = (event) => {
        event.preventDefault();
        console.log("handleRatingForm");

        formData.player_id = playerId;
        if (formData.update_date) {
            formData.update_date = changeLocalToUTCDate(formData.update_date);
        }

        console.log("formData", formData);

        handleRatingSubmit(formData);
    };

    const handleChange = (event, fieldName) => {
        let name = null;
        let value = null;

        if (event) {
            if (event.target) {
                name = event.target.name;
                value = event.target.value;
            } else {
                name = fieldName;
                value = event;
            }
            setFormData({
                name,
                value
            });
        }
    };

    return (
        <>
            <h2>{rating ? "Edit" : "New"} Rating</h2>
            <StyledFormWrapper onSubmit={handleRatingForm}>
                <FormControl>
                    <InputLabel htmlFor="rating">Rating</InputLabel>
                    <TextField
                        id="rating"
                        name="rating"
                        type="number"
                        value={formData.rating}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl>
                    <InputLabel htmlFor="update_date">Update Date</InputLabel>
                    <DatePicker
                        id="update_date"
                        name="update_date"
                        value={formData.update_date}
                        onChange={(event) => handleChange(event, "update_date")}
                        renderInput={(props) => <TextField {...props} />}
                    />
                </FormControl>
                <Button type="submit">{rating ? "Update" : "Add"} Rating</Button>
            </StyledFormWrapper>
        </>
    );
};

export default RatingForm;