import React, { useEffect } from "react";
import * as d3 from "d3";

const LineChart = (props) => {
    const { data } = props;

    useEffect(() => {
        d3.select(".season-balance-line-chart").selectChild("svg").remove();

        const createGraph = async () => {
            const parseTime = d3.timeParse("%Y-%m-%d");
            data.forEach((d) => {
                d.date = parseTime(d.date);
                d.balance = +d.balance;
            });

            let margin = { top: 20, right: 20, bottom: 50, left: 70 },
            width = 960 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom;
            // append the svg object to the body of the page
            let svg = d3.select(".season-balance-line-chart")
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`);

            // Add X axis and Y axis
            let x = d3.scaleTime().range([0, width]);
            let y = d3.scaleLinear().range([height, 0]);
            x.domain(d3.extent(data, (d) => { return d.date; }));
            y.domain([0, d3.max(data, (d) => { return d.balance; })]);
            svg.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(x));
            svg.append("g")
                .call(d3.axisLeft(y));

            let valueLine = d3.line()
                .x((d) => { return x(d.date); })
                .y((d) => { return y(d.balance); });

            svg.append("path")
                .data([data])
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", valueLine)
        };

        if (data && data.length > 0) {
            createGraph();
        }
    }, [data]);

    return (
        <div>
            <h1>Line Chart</h1>
            <section className="season-balance-line-chart"></section>
        </div>
    );
};

export default LineChart;