import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const SMTable = (props) => {
    const { headers, items, addEdit, handleEdit } = props;

    const renderTableHeaders = () => {
        if (headers && headers.length > 0) {
          const headerRow = headers.map((header, index) => {
            return <TableCell key={index}>{header.label}</TableCell>;
          });
          if (addEdit) {
            headerRow.push(<TableCell key="edit"></TableCell>);
          }
          return headerRow;
        }
        return [];
    };

    const renderCell = (item, key) => {
        if (typeof item[key] === "number") {
            return item[key].toLocaleString();
        }
        return item[key];
    };

    const renderTableRow = (item) => {
        const row = headers.map((header) => {
            return (
              <TableCell key={item.id + '-' + header.key}>
                  {renderCell(item, header.key)}
              </TableCell>
            );
        });
        if (addEdit) {
            row.push(
              <TableCell key={item.id + '-edit'}>
                  <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon />
                  </IconButton>
              </TableCell>
            );
        }
        return row;
    };

    const renderTableRows = () => {
        if (items && items.length > 0) {
          return items.map((item, index) => {
            if (headers && headers.length > 0) {
              return (
                <TableRow key={item.id}>
                  {renderTableRow(item)}
                </TableRow>
              );
            }
            return <tr key={index}></tr>;
          });
        }
        return [];
    };

    return (
        <>
          <Table className="table table-striped">
            <TableHead>
              <TableRow>{renderTableHeaders()}</TableRow>
            </TableHead>
            <TableBody>
              {renderTableRows()}
            </TableBody>
          </Table>
        </>
    )
}

export default SMTable;