import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Back = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <Button color="primary" onClick={() => navigate(-1)}>
                Back
            </Button>
        </>
    );
};

export default Back;