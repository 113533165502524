// LoginForm.js
import React from "react";

// Import custom hook
import usePersistedState from "../Custom/PersistedState";

// Import signInWithEmailAndPassword
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "@firebase/auth";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { Button } from "@mui/material";

import PageTitle from "./PageTitle";
import StyledFormWrapper from "../StyledComponents/Form/FormWrapper";

const provider = new GoogleAuthProvider();

const LoginForm = (props) => {
  // Initialize custom hook
  const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});
  // Constant now holds value from props.auth
  const auth = props.auth;
//   const provider = props.provider;

  const handleSubmit = (event) => {
    event.preventDefault();

    const enteredEmail = event.target[0].value;
    const enteredPassword = event.target[1].value;

    // TODO: do something with the values

    signInWithEmailAndPassword(auth, enteredEmail, enteredPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        setUserLoggedIn(user);
        alert("Logged in successfully");
        // Navigate to home after login
        window.location.pathname = "/home";
      })
      .catch((error) => {
        const errorCode = error.code;
        alert(errorCode);
        console.log(errorCode);
      });
  };

  const handleGoogleSignIn = () => {
      console.log("came into handleGoogleSignIn");
      console.log("auth", auth);
        console.log("provider", provider);
    signInWithPopup(auth, provider)
        .then((userCredential) => {
            const user = userCredential.user;
            setUserLoggedIn(user);
            alert("Logged in successfully");
            // Navigate to home after login
            window.location.pathname = "/home";
        })
        .catch((error) => {
            const errorCode = error.code;
            alert(errorCode);
            console.log(errorCode);
        });
  };

  return (
    <>
      <PageTitle
        title="Login"
      />
      <StyledFormWrapper onSubmit={handleSubmit}>
        <FormControl>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input id="email" type="email" />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input id="password" type="password" />
        </FormControl>
        <Button type="submit">Login</Button>
      </StyledFormWrapper>
      <Button type="button" onClick={handleGoogleSignIn}>Log in with Google</Button>
    </>
  );
};

export default LoginForm;