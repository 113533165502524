import React from "react";

import LoginForm from "../Components/LoginForm";

// Accept the prop
const AuthPage = (props) => {
  return (
    <>
      {/* Pass the auth from props to LoginForm */}
      <LoginForm auth={props.auth} />
    </>
  );
};

export default AuthPage;