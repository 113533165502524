import React, { useEffect } from "react";

const usePersistedState = (key, defaultValue) => {

  const [state, setState] = React.useState(
    () => localStorage.getItem(key) && localStorage.getItem(key) !== "undefined" ? JSON.parse(localStorage.getItem(key)) || defaultValue : null
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};
export default usePersistedState;