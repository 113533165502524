import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SeasonsDropdown = (props) => {
    const { seasons, value } = props;
    const [currentSeason, setCurrentSeason] = useState(value);

    // const [seasons, setSeasons] = React.useState([]);

    // useEffect(() => {
    //     const getSeasons = async () => {
    //         const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/seasons/`;
    //         const response = await axios.get(url);
    //         console.log("response", response);
    //         setSeasons(response.data);
    //     };
    //     getSeasons();
    // }, []);

    useEffect(() => {
        if (seasons && seasons.length > 0) {
            const today = new Date();
            const currentSeason = seasons.find((season) => {
                const startDate = new Date(season.start_date);
                const endDate = new Date(season.end_date);
                return startDate <= today && today <= endDate;
            });
            console.log("currentSeason:",currentSeason)
            if (currentSeason) {
                setCurrentSeason(currentSeason.id);
            } else {
                setCurrentSeason(seasons[0].id);
            }
        }
    }, [seasons]);

    const createSeasonOptions = () => {
        if (seasons && seasons.length > 0) {
            return seasons.map((season) => {
                return (
                    <MenuItem key={season.id} value={season.id}>{season.season_num}</MenuItem>
                );
            });
        }
        return [];
    };

    return (
        <>
            <FormControl>
                <InputLabel>Season #</InputLabel>
                {(value || currentSeason) && (
                    <Select
                    name="season_id"
                    onChange={props.handleChange}
                    defaultValue={value || currentSeason}
                    >
                        {createSeasonOptions()}
                    </Select>
                )}
            </FormControl>
        </>
    )
};

export default SeasonsDropdown;