import React, { useReducer } from "react";
import axios from "axios";

import StyledFormWrapper from "../../StyledComponents/Form/FormWrapper";
import { changeLocalToUTCDate } from "../../Custom/Util";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

const init = (season) => {
    return {
        season_num: season?.season_num || "",
        start_date: season?.start_date || null,
        end_date: season?.end_date || null,
    };
};

const formReducer = (state, action) => {
    if (action.type === "reset") {
        return {};
    }
    return {
        ...state,
        [action.name]: action.value
    }
}

const SeasonForm = (props) => {
    const { season, handleRefresh } = props;
    const [formData, setFormData] = useReducer(formReducer, init(season));

    const handleSeasonSubmit = (seasonData) => {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/seasons`;

        const resetForm = () => {
            setFormData({ type: "reset" });
        }

        const postNewSeason = async () => {
            const response = await axios.post(url, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        const putSeason = async () => {
            const response = await axios.put(`${url}/${season.id}`, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        if (season && season.id) {
            putSeason();
        } else {
            postNewSeason();
        }
    };

    const handleSeasonForm = (event) => {
        event.preventDefault();
        console.log("handleSeasonForm");

        console.log("formData", formData);
        if (formData.start_date) {
            formData.start_date = changeLocalToUTCDate(formData.start_date);
        }

        if (formData.end_date) {
            formData.end_date = changeLocalToUTCDate(formData.end_date);
        }

        handleSeasonSubmit(formData);
    };

    const handleChange = (event, fieldName) => {
        let name = null;
        let value = null;

        if (event) {
            if (event.target) {
                name = event.target.name;
                value = event.target.value;
            } else {
                name = fieldName;
                value = event;
            }
            setFormData({
                name,
                value
            });
        }
    };

    return (
        <>
            <h2>{season ? "Edit" : "New"} Season</h2>
            <StyledFormWrapper onSubmit={handleSeasonForm}>
                <FormControl>
                    <InputLabel htmlFor="season_num">Season #:</InputLabel>
                    <TextField
                        id="season_num"
                        name="season_num"
                        onChange={handleChange}
                        type="number"
                        value={formData.season_num}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label="Start Date"
                        name="start_date"
                        value={formData.start_date}
                        onChange={(event) => handleChange(event, "start_date")}
                        renderInput={(props) => <TextField {...props} />}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label="End Date"
                        name="end_date"
                        value={formData.end_date}
                        onChange={(event) => handleChange(event, "end_date")}
                        renderInput={(props) => <TextField {...props} />}
                    />
                </FormControl>
                <Button type="submit" value="Submit Season">{season ? "Update" : "Add"} Season</Button>
            </StyledFormWrapper>
        </>
    )
};

export default SeasonForm;