import styled from 'styled-components';

const StyledTopNav = styled.nav`
    background-color: #fff;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export default StyledTopNav;