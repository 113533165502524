import './App.css';

// Imports for routing and navigation
import { useRoutes, BrowserRouter as Router } from 'react-router-dom';

// Import our custom routes
import Routes from './Routes/Routes';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Import the IsLoggedIn function from AuthGuard
import IsLoggedIn from "./Auth/AuthGuard";
import TopNav from './Components/TopNav';
import StyledMainContent from './StyledComponents/MainContent';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const App = () => {
    // Calling the IsLoggedIn function
    const isAuthenticated = IsLoggedIn();
    
    const routing = useRoutes(
            Routes({ loggedIn: isAuthenticated, props: { auth: auth } })
        );
      return routing;
}

const AppWrapper = () => {
  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TopNav
          loggedIn={IsLoggedIn()}
          auth={auth}
        />
        <StyledMainContent>
          <App />
        </StyledMainContent>
      </LocalizationProvider>
    </Router>
  )
}

export default AppWrapper;
