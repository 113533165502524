import React, { useEffect, useState} from "react";
import axios from "axios";

import PageTitle from "../Components/PageTitle";
import SMTable from "../Components/SMTable";
import StyledMainArticle from "../StyledComponents/MainArticle";
import SeasonForm from "../Components/Forms/SeasonForm";
import { Button } from "@mui/material";

const Seasons = (props) => {
    const [seasons, setSeasons] = useState([]);
    const [season, setSeason] = useState(null);
    const [showSeasonForm, setShowSeasonForm] = useState(false);
    const [reloadData, setReloadData] = useState(true);

    const headers = [
        {
            key: "season_num",
            label: "Season Number",
        },
        {
            key: "start_date",
            label: "Start Date",
        },
        {
            key: "end_date",
            label: "End Date",
        },
    ]

    useEffect(() => {
        const getSeasons = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/seasons`;
            const response = await axios.get(url);
            console.log("response", response);
            setSeasons(response.data);
        };

        if (reloadData) {
            getSeasons();
            setReloadData(false);
        }
    }, [reloadData]);

    const addNewSeason = () => {
        setShowSeasonForm(true);
    };

    const displaySeasonForm = (season = null) => {
        console.log("season", season);
        setShowSeasonForm(false);
        if (season) {
            setSeason(season);
        }
        setShowSeasonForm(true);
    };

    const reloadPage = () => {
        setSeason(null);
        setShowSeasonForm(false);
        setReloadData(true);
    };

    return (
        <>
            <PageTitle title="Seasons" />
            <StyledMainArticle>
                <section>
                    <Button onClick={addNewSeason}>
                        {showSeasonForm ? "Hide Form" : "Add New Season"}
                    </Button>
                </section>
                {showSeasonForm && (
                    <SeasonForm
                        season={season}
                        handleRefresh={reloadPage}
                    />)}
                <SMTable
                    headers={headers}
                    items={seasons}
                    addEdit={true}
                    handleEdit={displaySeasonForm}
                />
            </StyledMainArticle>
        </>
    );
};

export default Seasons;