import React from "react";
import StyledTitle from "../StyledComponents/PageTitle";

const PageTitle = (props) => {
    const { title } = props;
    return (
        <StyledTitle>
            {title}
        </StyledTitle>
    );
};

export default PageTitle;