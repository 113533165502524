import { DatePicker } from "@mui/lab";
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, TextField } from "@mui/material";
import axios from "axios";
import React, { useReducer } from "react";

import StyledFormWrapper from "../StyledComponents/Form/FormWrapper";
import SeasonsDropdown from "./SeasonsDropdown";
import lightFormat from "date-fns/lightFormat";

const init = (transfer) => {
    console.log("transfer in init:", transfer)
    return {
        season_id: transfer?.season_id,
        player_id: transfer?.player_id,
        is_transfer_out: transfer?.is_transfer_out,
        transfer_date: transfer?.transfer_date,
        transfer_amount: transfer?.transfer_amount,
    }
}

const formReducer = (state, action) => {
    if (action.type === "reset") {
        return {};
    }
    return {
        ...state,
        [action.name]: action.value
    }
}

const TransferForm = (props) => {
    const { transfer, playerId, seasons, handleRefresh } = props;
    const [formData, setFormData] = useReducer(formReducer, init(transfer));

    const handleTransferSubmit = (transferData) => {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/transfers`;

        const postNewTransfer = async () => {
            const response = await axios.post(url, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        const putTransfer = async () => {
            const response = await axios.put(`${url}/${transfer.id}`, formData);
            console.log("response", response);
            // resetForm();
            handleRefresh();
        }

        if (transfer && transfer.id) {
            putTransfer();
        } else {
            postNewTransfer();
        }
    };

    const handleTransferForm = (event) => {
        event.preventDefault();
        console.log("handleTransferForm");

        formData.player_id = playerId;
        if (!formData.season_id) {
            const today = new Date();
            const currentSeason = seasons.find(season => {
                const startDate = new Date(season.start_date);
                const endDate = new Date(season.end_date);
                return startDate <= today && today <= endDate;
            });
            formData.season_id = currentSeason.id;
        }

        if (formData.is_transfer_out === undefined) {
            formData.is_transfer_out = false;
        }

        if (formData.transfer_date === undefined) {
            formData.transfer_date = new Date();
        }

        const transferDate = lightFormat(formData.transfer_date, "yyyy-MM-dd")
        formData.transfer_date = new Date(transferDate).toISOString();

        handleTransferSubmit(formData);
    }

    const handleChange = event => {
        console.log("event in handleChange", event);
        let name = null;
        let value = null;
        if (event) {
            if (event.target) {
                if (event.target.name === "is_transfer_out") {
                    name = event.target.name;
                    value = event.target.checked;
                } else {
                    name = event.target.name;
                    value = event.target.value;
                }
            } else {
                name = "transfer_date";
                value = event;
            }
            setFormData({
                name: name,
                value: value,
            });
        }
    };

    return (
        <>
            <h2>{transfer ? "Edit" : "New"} Transfer</h2>
            <StyledFormWrapper onSubmit={handleTransferForm}>
                <FormControl>
                    <DatePicker
                        label="Transfer Date"
                        name="transfer_date"
                        value={formData.transfer_date}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormControlLabel control={<Checkbox onChange={handleChange} name="is_transfer_out" />} label="Is Transfer Out"/>
                </FormControl>
                <FormControl>
                    <InputLabel htmlFor="transfer_amount">Transfer Amount:</InputLabel>
                    <TextField
                        id="transfer_amount"
                        name="transfer_amount"
                        onChange={handleChange}
                        type="number"
                        value={formData.transfer_amount}
                    />
                </FormControl>
                <SeasonsDropdown
                    handleChange={handleChange}
                    seasons={seasons}
                    value={formData.season_id}
                />
                <Button type="submit" value="Add Transfer">{transfer ? "Update" : "Add"} Transfer</Button>
            </StyledFormWrapper>
        </>
    )
}

export default TransferForm;