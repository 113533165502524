import React, { useEffect, useState } from "react";

// Import our custom hook here again
import usePersistedState from "../Custom/PersistedState";

// Import signOut from Firebase Auth
import { signOut } from "firebase/auth";
import PlayerTable from "../Components/PlayerTable";
import AddPlayerForm from "../Components/AddPlayerForm";
import axios from "axios";
import PageTitle from "../Components/PageTitle";
import StyledMainArticle from "../StyledComponents/MainArticle";
import { Button } from "@mui/material";
import dayjs from 'dayjs';

const HomePage = (props) => {
    const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});

    const [players, setPlayers] = useState([]);
    const [showNewPlayerForm, setShowNewPlayerForm] = useState(false);
    const headers = [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "age",
            label: "Age",
        },
        {
            key: "daysUntilBirthday",
            label: "Days Until Birthday",
        }
    ];

    const calculateDaysUntilBirthday = (players) => {
        const currentDate = dayjs()
        const currentMonth = currentDate.month()
        const currentYear = currentDate.year()

        return players.map((player) => {
            const playerBirthdate = dayjs(player.dob)
            const playerBirthdateMonth = playerBirthdate.month()
            const playerBirthdateToUse = dayjs(player.dob).year(currentMonth === 11 && playerBirthdateMonth === 0 ? currentYear + 1 : currentMonth === 0 && playerBirthdateMonth === 11 ? currentYear - 1: currentYear)

            const dateDiff = playerBirthdateToUse.diff(currentDate, "day")

            player.daysUntilBirthday = dateDiff > 0 ? dateDiff : 0;

            return player;
        });
    }

    useEffect(() => {
        const getPlayers = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/players/transfer-within/14`;
            const response = await axios.get(url);
            console.log("response", response);
            if (response) {
                if (response.data?.length) {
                    const playersWithDetails = calculateDaysUntilBirthday(response.data);
                    setPlayers(playersWithDetails);
                }
            }
        }
        getPlayers();
    }, []);

    const triggerNewPlayerForm = () => {
        setShowNewPlayerForm(!showNewPlayerForm);
    }

    return (
        <>
            <PageTitle title="Dashboard" />
            <StyledMainArticle>
                <Button color="success" onClick={triggerNewPlayerForm}>
                    {showNewPlayerForm ? "Hide Form" : "Add New Player"}
                </Button>
                {showNewPlayerForm && (
                    <AddPlayerForm />
                )}
                <PlayerTable
                    headers={headers}
                    players={players}
                />
            </StyledMainArticle>
        </>
    )
}

export default HomePage;