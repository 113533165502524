import React, { useEffect, useState } from "react";
import PlayerTable from "../Components/PlayerTable";
import AddPlayerForm from "../Components/AddPlayerForm";
import axios from "axios";
import PageTitle from "../Components/PageTitle";
import StyledMainArticle from "../StyledComponents/MainArticle";
import { Button } from "@mui/material";

const CurrentRoster = (props) => {
    const [players, setPlayers] = useState([]);
    const [showNewPlayerForm, setShowNewPlayerForm] = useState(false);
    const headers = [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "age",
            label: "Age",
        },
        {
            key: "current_rating",
            label: "Current Rating",
        },
    ];

    useEffect(() => {
        const getPlayers = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/players/current_roster`;
            const response = await axios.get(url);
            console.log("response", response);
            if (response) {
                const populatedPlayers = response.data.map((player) => {
                    return {
                        ...player,
                        current_rating: player.ratings && player.ratings.length > 0 ? player.ratings[0].rating : "-",
                    }
                });
                setPlayers(populatedPlayers);
            }
        }
        getPlayers();
    }, []);

    const triggerNewPlayerForm = () => {
        setShowNewPlayerForm(!showNewPlayerForm);
    }

    return (
        <>
            <PageTitle title="Current Roster" />
            <StyledMainArticle>
                <section>
                    <h3>Stats</h3>
                    <p># of players: {players.length}</p>
                </section>
                <Button color="success" onClick={triggerNewPlayerForm}>
                    {showNewPlayerForm ? "Hide Form" : "Add New Player"}
                </Button>
                {showNewPlayerForm && (
                    <AddPlayerForm />
                )}
                <PlayerTable
                    headers={headers}
                    players={players}
                />
            </StyledMainArticle>
        </>
    )
};

export default CurrentRoster;