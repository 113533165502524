import React from "react";
import { signOut } from "firebase/auth";
import usePersistedState from "../Custom/PersistedState";

import Button from '@mui/material/Button';
import StyledTopNav from "../StyledComponents/TopNav";

const TopNav = (props) => {
    const { loggedIn, auth } = props;
    const [userLoggedIn, setUserLoggedIn] = usePersistedState("user", {});

    const handleSignOut = async () => {
        await signOut(auth);
        setUserLoggedIn({});
        window.location.pathname = "/login";
    }

    return (
        <>
            <StyledTopNav className="navbar navbar-expand-lg navbar-light bg-light">
                <section>
                    <a className="navbar-brand" href="/">
                        SM
                    </a>
                </section>
                {loggedIn && (
                    <>
                        <section className="navbar-nav">
                            <a className="nav-item nav-link" href="/current-roster">
                                Current Roster
                            </a>
                        </section>
                        <section className="navbar-nav">
                            <a className="nav-item nav-link" href="/balance">
                                Balance
                            </a>
                        </section>
                        <section className="navbar-nav">
                            <a className="nav-item nav-link" href="/seasons">
                                Seasons
                            </a>
                        </section>
                    </>
                )}
                <section className="navbar-nav">

                    {loggedIn && (
                        <Button variant="outlined" color="secondary" onClick={() => handleSignOut()}>
                            Log Out
                        </Button>
                    )}
                </section>
            </StyledTopNav>
        </>
    )
};

export default TopNav;