import styled from "styled-components";

const StyledFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
        padding: 8px 0;
    }
    .MuiFormControl-root {
        padding: 16px 0;
    }
`;

export default StyledFormWrapper;