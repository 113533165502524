import React from "react";

import usePersistedState from "../Custom/PersistedState";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (error) {
    return null;
  }
};

const IsLoggedIn = () => {
  try {
    const user = usePersistedState("user");
    const userObj = user[0];
    console.log("userObj", userObj);

    if (userObj && userObj.stsTokenManager) {
      const decodedJWT = parseJwt(userObj.stsTokenManager.accessToken);

      const jwtExpiration = decodedJWT.exp + 1000 * 60 * 60 * 24;

      if (jwtExpiration * 1000 < Date.now()) {
        return false;
      } else {
        return true;
      }
    }
  } catch (error) {
    console.error("Error in IsLoggedIn:", error);
    return false;
  }
};

export default IsLoggedIn;