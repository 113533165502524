import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import StyledSMTable from "../StyledComponents/SMTable";

const PlayerTable = (props) => {

  const { headers, players, playerIdKey } = props;

  const renderTableHeaders = () => {
    if (headers && headers.length > 0) {
      return headers.map((header, index) => {
        return <TableCell key={index}>{header.label}</TableCell>;
      });
    }
    return [];
  };

  const renderCell = (player, key) => {
    if (key === "name") {
      return <a href={`/player/${playerIdKey ? player[playerIdKey] : player.id}`}>{player[key]}</a>;
    } else if (typeof player[key] === "number") {
      return player[key].toLocaleString();
    }
    return player[key];
  }

  const renderTableRows = () => {
    console.log("players in renderTableRows", players);
    if (players && players.length > 0) {
      return players.map((player, index) => {
        if (headers && headers.length > 0) {
          return (
            <TableRow key={player.id}>
              {headers.map((header) => {
                return (
                  <TableCell key={player.id + '-' + header.key}>
                    {renderCell(player, header.key)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        }
        return <TableRow key={index}></TableRow>;
      });
    }
    return [];
  };

  return (
    <>
      {/* <table className="table table-striped">
        <thead>
          <tr>{renderTableHeaders()}</tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table> */}
      <StyledSMTable>
        <TableHead>
          <TableRow>
            {renderTableHeaders()}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows()}
        </TableBody>
      {/* <thead>
          <tr>{renderTableHeaders()}</tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody> */}
      </StyledSMTable>
    </>
  )
}

export default PlayerTable;