import React from "react";
import { Navigate } from "react-router-dom";

// Import pages
import HomePage from "../Pages/HomePage";
import AuthPage from "../Pages/AuthPage";
import Player from "../Pages/Player";
import CurrentRoster from "../Pages/CurrentRoster";
import Balance from "../Pages/Balance";
import Seasons from "../Pages/Seasons";

const checkLoggedIn = (IsLoggedIn, element) => {
    return IsLoggedIn ? element : <Navigate to="/login" />;
}

const routes = ({ loggedIn: IsLoggedIn, props: props}) => [
    {
        path: "home",
        element: IsLoggedIn ? <HomePage {...props} /> : <Navigate to="/login" />,
    },
    {
        path: "login",
        element: <AuthPage {...props} />,
    },
    {
        path: "player/:playerId",
        element: checkLoggedIn(IsLoggedIn, <Player {...props} />),
    },
    {
        path: "current-roster",
        element: checkLoggedIn(IsLoggedIn, <CurrentRoster {...props} />),
    },
    {
        path: "balance",
        element: checkLoggedIn(IsLoggedIn, <Balance {...props} />),
    },
    {
        path: "seasons",
        element: checkLoggedIn(IsLoggedIn, <Seasons {...props} />),
    },
    {
        path: "",
        element: IsLoggedIn ? <HomePage {...props} /> : <Navigate to="/login" />,
    }
];

export default routes;