import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import SMTable from "../Components/SMTable";
import PageTitle from "../Components/PageTitle";
import StyledMainArticle from "../StyledComponents/MainArticle";
import Back from "../Components/BackButton";
import { Button } from "@mui/material";
import EditPlayerForm from "../Components/EditPlayerForm";
import TransferForm from "../Components/TransferForm";
import RatingForm from "../Components/Forms/RatingForm";

const Player = (props) => {
    const { playerId } = useParams();
    const [reloadData, setReloadData] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [player, setPlayer] = useState({});
    const [seasons, setSeasons] = useState([]);
    const [showTransferForm, setShowTransferForm] = useState(false);
    const [transfer, setTransfer] = useState(null);
    const [showEditPlayerForm, setShowEditPlayerForm] = useState(false);
    const [showRatingForm, setShowRatingForm] = useState(false);
    const [rating, setRating] = useState(null);
    const [positions, setPositions] = useState([]);

    const transferHeaders = [
        {
            key: "transfer_direction",
            label: "Transfer Direction",
        },
        {
            key: "season_num",
            label: "Transfer Season",
        },
        {
            key: "transfer_date",
            label: "Transfer Date",
        },
        {
            key: "transfer_amount",
            label: "Transfer Amount",
        },
    ];

    const ratingHeaders = [
        {
            key: "update_date",
            label: "Update Date",
        },
        {
            key: "original_rating",
            label: "Original Rating",
        },
        {
            key: "new_rating",
            label: "New Rating",
        },
        {
            key: "rating_change",
            label: "Rating Change",
        },
    ];

    const triggerTransferForm = () => {
        setShowTransferForm(!showTransferForm);
    };

    const triggerEditPlayerForm = () => {
        setShowEditPlayerForm(!showEditPlayerForm);
    };

    const triggerRatingForm = (rating = null) => {
        console.log("rating", rating);
        if (rating) {
            setRating(rating);
        }
        setShowRatingForm(!showRatingForm);
    };

    const reloadPage = () => {
        setTransfer({});
        setShowTransferForm(false);
        setShowEditPlayerForm(false);
        setShowRatingForm(false);
        setRating(null);
        setReloadData(true);
    };

    useEffect(() => {
        const getPlayer = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/players/${playerId}`;
            const response = await axios.get(url);
            console.log("response", response);
            const data = response.data;
            if (data && data.transfers) {
                data.transfers = data.transfers.map((transfer) => {
                    transfer.transfer_direction = transfer.is_transfer_out ? "Out" : "In";
                    return transfer;
                });
            }

            if (data && data.ratings) {
                data.ratings = data.ratings.map((rating, index) => {
                    if (index < data.ratings.length - 1) {
                        const previousRating = data.ratings[index + 1];
                        rating.new_rating = rating.rating;
                        rating.original_rating = previousRating.rating;
                        rating.rating_change = rating.rating - previousRating.rating;
                    } else {
                        rating.new_rating = rating.rating;
                        rating.original_rating = "-";
                        rating.rating_change = "-";
                    }
                    return rating;
                });
            }
            setPlayer(response.data);
        };

        if (reloadData) {
            getPlayer();
            setReloadData(false);
        }
    }, [reloadData]);

    useEffect(() => {
        const getSeasons = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/seasons`;
            const response = await axios.get(url);
            console.log("response", response);
            setSeasons(response.data);
        };

        const getPositions = async () => {
            const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/positions/`;
            const response = await axios.get(url);
            console.log("response", response);
            setPositions(response.data);
        };

        getSeasons();
        getPositions();
    }, []);

    const triggerEditTransferForm = (transfer) => {
        console.log("edit transfer", transfer);
        setTransfer(transfer);
        setShowTransferForm(true);
    }

    const getCurrentRating = () => {
        if (player && player.ratings && player.ratings.length > 0) {
            const currentTransfer = player.ratings[0];
            return currentTransfer.rating;
        }
        return "-";
    };

    const getCurrentValue = () => {
        if (player && player.value) {
            return player.value;
        }
        return "-";
    };

    return (
        <>
            <PageTitle 
                title={player ? player.name : "Loading Player..."}
            />
            <StyledMainArticle>
                <Back />
                <>
                    {player ? (
                        <>
                            <Button onClick={triggerEditPlayerForm}>Edit Player</Button>
                            <p>Player ID: {playerId}</p>
                            {!showEditPlayerForm ? (
                                <>
                                    <p>Name: {player.name}</p>
                                    <p>Birthdate: {player.dob} ({player.age})</p>
                                    <p>Current Rating: {getCurrentRating()}</p>
                                    <p>Current Value: {getCurrentValue()}</p>
                                </>
                            ) : (
                                <EditPlayerForm
                                    player={player}
                                    handleRefresh={reloadPage}
                                />
                            )}
                            <section>
                                <Button onClick={triggerTransferForm}>
                                    {showTransferForm ? "Hide Form" : "Add New Transfer"}
                                </Button>
                                <Button onClick={triggerRatingForm}>
                                    {showRatingForm ? "Hide Form" : "Add New Rating"}
                                </Button>
                                {showTransferForm && (
                                    // <AddTransferForm 
                                    //     seasons={seasons}
                                    //     playerId={playerId}
                                    //     finishedNewTransferCreation={reloadPage}
                                    // />
                                    <TransferForm
                                        playerId={playerId}
                                        seasons={seasons}
                                        transfer={transfer}
                                        handleRefresh={reloadPage}
                                    />
                                )}
                                {showRatingForm && (
                                    <RatingForm
                                        playerId={playerId}
                                        rating={rating}
                                        handleRefresh={reloadPage}
                                    />
                                )}
                                <h3>Transfers</h3>
                                <SMTable 
                                    headers={transferHeaders}
                                    items={player.transfers}
                                    addEdit={true}
                                    handleEdit={triggerEditTransferForm}
                                />
                            </section>
                            <section>
                                <h3>Ratings</h3>
                                <SMTable
                                    headers={ratingHeaders}
                                    items={player.ratings}
                                    addEdit={true}
                                    handleEdit={triggerRatingForm}
                                />
                            </section>
                        </>
                    ) : (
                        <p>Player Not Found</p>
                    )}
                </>
            </StyledMainArticle>
        </>
    )
};

export default Player;